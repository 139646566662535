<style scoped>
    .record-container {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
    }
</style>

<template>
    <v-container class="record-container pa-4">
        <v-data-iterator :items="friends" :search="search" hide-default-footer :loading="friendsLoading" style="width:100%;">
            <template v-slot:header>
                <v-text-field v-model="search" clearable flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" class="mb-5" label="Search"></v-text-field>
            </template>
            <template v-slot:default="props">
                <v-row>
                    <v-col v-for="item in props.items" :key="item.id" cols="12">
                        <v-card class="pa-4 mb-4 mt-4" elevation="0" style="border:var(--border);"><person-card :item="item" :button="{ title: 'select' }" @button="select"></person-card></v-card> 
                    </v-col>
                </v-row>
            </template>
            <template v-slot:loading>
                <div style="display:flex;justify-content:center;" class="pa-8">
                    <app-loader state="loading"></app-loader>
                </div>
            </template>            
        </v-data-iterator>            
    </v-container> 
</template>

<script>
//import events from "../../../app/app.events";
import personCard from '../../person/person.card.vue';
import appLoader from "../../../app/components/app.loader.vue";

export default { 
    data() {
        return {
            search: "",
            friends: [],
            friendsLoading: true,
        }
    },
    async mounted() {
        this.friends = await this.getFriends();
        this.friendsLoading = false;        
    },
    created() {
    },
    computed: {
    },
    methods: {
        async getFriends() {
            var results = await this.$api.get("user/getFriends", { token: this.$root.authToken });
            
            var friends;
            if (results.data == "") {
                friends = [];
            }
            else {
                friends = results.data.list;
            }

            return Object.values(friends);
        },
        async select(item) {
            this.$emit("select", item);
        }
    },
    components: {
        personCard,
        appLoader
    }
}
</script>